<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
            @click="showFormModal(null)"
            class="px-3 text-capitalize"
            variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد امکانات
        </b-button>
      </b-col>
    </b-row>



<!--    <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
    enabled: true
  }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    />-->

    <div v-if="packages">
      <div class="d-flex justify-content-between align-items-center">
        <per-page class="col-sm-12 col-md-3 col-lg-2" v-model="perPage" />
        <!-- <search
          ref="residencesSearchComponent"
          class="w-50"
          v-model="searchInput"
        /> -->
      </div>
      <vue-good-table
          :columns="columns"
          :rows="packages"
          :search-options="{
            enabled: true
          }"
          :pagination-options="{
        enabled: true,
        perPage:pageLength
      }">
        <template
            slot="table-row"
            slot-scope="props"
        >
          <div
              v-if="props.column.field === 'icon'"
              class="text-nowrap"
          >
            icon
            <span class="text-nowrap">{{ props.row.icon }}</span>
          </div>
          <div v-else-if="props.column.field === 'name'">
            {{ props.row.name }}
          </div>
          <div v-else-if="props.column.field === 'description'">
            {{ props.row.description }}
          </div>
          <div v-else-if="props.column.field === 'created_at'">
            {{
              new Date(props.row.created_at).toLocaleString("fa-IR", {
                timeZone: "Iran",
              })
            }}
          </div>
          <div v-else-if="props.column.field === 'status'">
            <b-col
                class="d-flex align-items-center pl-0"
                :class="
                  props.row.status != 'active' ? 'text-danger' : 'text-success'
                "
            >
              {{ props.row.status == "active" ? "" : "غیر" }}فعال
            </b-col>
          </div>
          <div v-else>
            <b-col class="d-flex align-items-center">
              <b-button
                  @click="showConfirmModal(props.row)"
                  variant="flat-danger"
                  class="font-weight-bolder"
              >
                <feather-icon icon="Trash2Icon" size="20" />
              </b-button>
              <b-button
                  @click="showFormModal(props.row)"
                  variant="flat-secondary"
                  class="font-weight-bolder"
              >
                <feather-icon icon="Edit2Icon" size="20" />
              </b-button>
            </b-col>
          </div>
        </template>
        
      </vue-good-table>
<!--      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th>عکس آیکن</b-th>
            <b-th>آیکن</b-th>
            <b-th>عنوان</b-th>
            <b-th>توضیحات</b-th>
            <b-th>تاریخ</b-th>
            <b-th>وضعیت</b-th>
            <b-th>عملیات</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="packagee in packages" :key="packagee.id">
            <b-th>
              <img
                  v-if="packagee.image.path"
                  style="border-radius: 15px; width: 35px"
                  :src="$http.defaults.fileUrl + packagee.image.path"
              /></b-th>
            <b-th >{{ packagee.icon }}</b-th>
            <b-td>{{ packagee.name }}</b-td>
            <b-td>{{ packagee.description }}</b-td>
            <b-td>
              {{
                new Date(packagee.created_at).toLocaleString("fa-IR", {
                  timeZone: "Iran",
                })
              }}
            </b-td>
            <b-td>
              <b-col
                  class="d-flex align-items-center pl-0"
                  :class="
                  packagee.status != 'active' ? 'text-danger' : 'text-success'
                "
              >
                {{ packagee.status == "active" ? "" : "غیر" }}فعال
              </b-col>
            </b-td
            >
            <b-td>
              <b-col class="d-flex align-items-center">
                <b-button
                    @click="showConfirmModal(packagee)"
                    variant="flat-danger"
                    class="font-weight-bolder"
                >
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>
                <b-button
                    @click="showFormModal(packagee)"
                    variant="flat-secondary"
                    class="font-weight-bolder"
                >
                  <feather-icon icon="Edit2Icon" size="20" />
                </b-button>
              </b-col>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <pagination
          ref="residencesPaginationComponent"
          v-model="page"
          :paginationData="paginationData"
      />-->
    </div>
    <b-sidebar
        width="500px"
        v-model="formModal"
        right
        backdrop
        shadow
        bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
                style="background-color: rgba(75, 75, 75, 0.12)"
                @click="formModal = false"
                variant="flat-dark"
                class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش امکانات" : "ایجاد امکانات" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-1">
          <label for="name" class="text-capitalize"> عنوان : </label>
          <b-form-input @input="removeErrors('name')" id="name" v-model="packageInput.name" type="text" />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="slug" class="text-capitalize"> اسلاگ | slug : </label>
          <b-form-input @input="removeErrors('slug')" id="slug" v-model="packageInput.slug" type="text" />
          <ErrorMessage
              errorType="slug"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby }">
          <label for="province_status" class="text-capitalize"> وضعیت : </label>
          <b-form-checkbox
              v-model="packageInput.status"
              :aria-describedby="ariaDescribedby"
              value="active"
              @input="removeErrors('status')"
          >فعال</b-form-checkbox
          >
          <b-form-checkbox
              v-model="packageInput.status"
              :aria-describedby="ariaDescribedby"
              value="inactive"
              @input="removeErrors('status')"
          >پیش نویس</b-form-checkbox
          >
          <ErrorMessage
              errorType="status"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="description" class="text-capitalize"> توضیحات : </label>
          <b-form-input
              id="description"
              v-model="packageInput.description"
              type="text"
              @input="removeErrors('description')"
          />
          <ErrorMessage
              errorType="description"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="body" class="text-capitalize"> آیکن : </label>
          <b-form-input @input="removeErrors('icon')"  id="body" v-model="packageInput.icon" type="text" />
          <ErrorMessage
              errorType="icon"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="">
          <label for="imageIcon" class="text-capitalize">
            آپلود تصویر ایکون:
          </label>
          <FilePicker
              @inputFile="inputIconImageFile"
              :url="iconImageurl"
              ref="iconImageFilePicker"
          ></FilePicker>
          <ErrorMessage
              errorType="image"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
              class="text-capitalize mr-2"
              @click="formModal = false"
              variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormCheckbox,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ErrorMessage from "@/components/ErrorMessage.vue";
import FilePicker from "@/components/FilePicker.vue";

export default {
  components: {
    VueGoodTable,
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    ErrorMessage,
    BFormCheckbox,
    FilePicker,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label:  'ایکون گوگل',
          field: 'icon',
        },
        {
          label: 'عنوان',
          field: 'name',
        },
        {
          label: 'توضیحات',
          field: 'description',
        },
        {
          label: 'تاریخ',
          field: 'created_at',
        },
        {
          label: 'وضعیت',
          field: 'status',
        },
        {
          label: 'عملیات',
          field: 'action',
        },
      ],
      rows: [
        { id:1, name:"John", age: 20},
        { id:2, name:"Jane", age: 24 },
        { id:3, name:"Susan", age: 16 },
        { id:4, name:"Chris", age: 55 },
        { id:5, name:"Dan", age: 40, },
        { id:6, name:"John", age: 20 },
      ],


      iconImageurl:"",
      errorMessage: {},
      perPage: 10,
      page: 1,
      buildings: [],
      packages: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,

      OpenIndicator: {
        render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      buildingsCancelToken: undefined,
      packageInput: {
        id: "",
        name: "",
        slug:"",
        description: "",
        status:"",
        image: null,
        icon: "",
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getPackages();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getPackages();
    },
    perPage(value) {
      this.page = 1;
      this.getPackages();
    },
    page(value) {
      this.getPackages();
    },
  },
  methods: {
    inputIconImageFile(file) {
      this.removeErrors("image");
      this.packageInput.image = file;
    },
    removeErrors(data) {
      if (
          typeof this.errorMessage[data] !== "undefined" &&
          this.errorMessage[data].length > 0
      ) {
        // this.errorMessage[data][0]="salllam"
        delete this.errorMessage[data];
        this.errorMessage = Object.assign(
            {},
            this.errorMessage,
            this.errorMessage
        );
        console.log(data, this.errorMessage);
      }
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getPackages();
    },

    async getPackages() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/residences/packages",
        // params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.packages = data.data;
            this.paginationData = data.meta;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    showConfirmModal(packagee) {
      this.$bvModal
          .msgBoxConfirm(
              `آیا از حذف امکانات مطمئن هستید ؟  : ${packagee.name}.`,
              {
                title: this.$t("Please Confirm"),
                size: "sm",
                okVariant: "primary",
                okTitle: this.$t("Yes"),
                cancelTitle: this.$t("No"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let residenceIndex = 0;
              this.packages = await this.packages.filter(
                  (residenceItem, index) => {
                    if (residenceItem.id == packagee.id) {
                      residenceIndex = index;
                    }
                    return residenceItem.id !== packagee.id;
                  }
              );

              const requestData = {
                method: "delete",
                url: `/admin/residences/packages/${packagee.id}`,
              };
              this.$http(requestData)
                  .then((response) => {
                    this.$bvToast.toast(
                        `امکانات: ${packagee.name}  با موفقیت حذف شد.`,
                        {
                          title: this.$t("Done"),
                          variant: "success",
                          solid: true,
                        }
                    );
                  })
                  .catch((err) => {
                    let message = this.$t("Unable to delete!");
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    )
                      message = err.response.data.message;
                    this.$bvToast.toast(message, {
                      title: this.$t("Error"),
                      variant: "danger",
                      solid: true,
                    });
                    this.packages.splice(residenceIndex, 0, packagee);
                  });
            }
          });
    },
    showFormModal(packagee) {
      if (packagee) {
        this.packageInput = {
          id: packagee.id,
          name: packagee.name,
          slug:packagee.slug,
          description: packagee.description,
          status: packagee.status,
          image: null,
          icon: packagee.icon,
        };
        this.$refs.iconImageFilePicker.clearFileInput()
        this.iconImageurl=packagee.image.path
        this.editMode = true;
      } else {
        this.packageInput = {
          name: "",
          slug: "",
          description: "",
          status: "",
          image: "",
          icon: "",
        };
        this.$refs.iconImageFilePicker.clearFileInput()
        this.iconImageurl=""
        this.editMode = false;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.packageInput.name) {
        errorMessage = "لطفا نام امکانات را وارد کنید";
      }
      if (!errorMessage && !this.packageInput.slug) {
        errorMessage = "لطفا اسلاگ امکانات را وارد کنید";
      }

      let formData = new FormData();

      if (this.packageInput.name) {
        formData.append("name", this.packageInput.name);
      }
      if (this.packageInput.slug) {
        formData.append("slug", this.packageInput.slug);
      }
      if (this.packageInput.status) {
        formData.append("status", this.packageInput.status);
      }
      if (this.packageInput.description) {
        formData.append("description", this.packageInput.description);
      }
      if (this.packageInput.image) {
        formData.append("image", this.packageInput.image);
      }
      if (this.packageInput.icon) {
        formData.append("icon", this.packageInput.icon);
      }
      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "post" : "post",
        url: this.editMode
            ? `/admin/residences/packages/update/${this.packageInput.id}`
            : `/admin/residences/packages`,
        data: formData,
      };

      this.$http(requestData)
          .then((response) => {
            let message = "امکانات با موفقیت اضافه شد";
            if (this.editMode) message = "امکانات با موفقیت ویرایش شد";
            this.$bvToast.toast(message, {
              title: this.$t("Done"),
              variant: "success",
              solid: true,
            });
            if (!this.editMode && this.buildings && this.buildings.length > 0) {
              this.$refs.residencesPaginationComponent.clear();
              // this.$refs.residencesSearchComponent.clear();
              this.$refs.residencesTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.getPackages();
          })
          .catch((err) => {
            let message = "خطا در ایجاد امکانات";
            if (this.editMode) message = "خطا در ویرایش امکانات";
            if (
                err.response &&
                err.response.data &&
                err.response.data.response &&
                err.response.data.response.message
            )
              message = err.response.data.response.message;
            if (
                err.response &&
                err.response.data &&
                err.response.data.response &&
                err.response.data.response.meta &&
                err.response.data.response.meta.errors
            )
              this.errorMessage = err.response.data.response.meta.errors;
            console.log("this.errorMessage", this.errorMessage);

            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
            });
            this.submitLoading = false;
            console.log(err);
          });
    },

  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
